import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Quiz from "./Quiz";
import LearnBasics from "./learn/Basics";
import LearnCompounds from "./learn/Compounds";
import LearnPeriodicTable from "./learn/PeriodicTable";
import SignificantFigures from "./learn/SignificantFigures";
import FiveQuestionQuiz from "./quizzes/FiveQuestionQuiz";
import TenQuestionQuiz from "./quizzes/TenQuestionQuiz";
import Olympiad from "./quizzes/Olympiad";
import Quizzes from "./Quizzes";
import About from "./About";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="app">
        <nav className="navbar">
          <div className="logoimg"><img src={require('./assets/textlogo.png')} alt="Compound Guesser" /></div>
          <ul className="nav-links">
            <li>
              <Link to="/">Quiz</Link>
            </li>
            <li className="dropdown">
              <Link to="/learn" className="dropbtn">Learn</Link>
              <div className="dropdown-content">
                <Link to="/learn/basics">Basics</Link>
                <Link to="/learn/compounds">Compounds</Link>
                <Link to="/learn/periodic-table">Periodic Table</Link>
                <Link to="/learn/sig-figs">Significant Figures</Link>
              </div>
            </li>
            <li className="dropdown">
              <Link to="/quizzes" className="dropbtn">Quizzes</Link>
              <div className="dropdown-content">
                <Link to="/quizzes/FiveQuestionQuiz">5 Q's</Link>
                <Link to="/quizzes/TenQuestionQuiz">10 Q's</Link>
                <Link to="/quizzes/Olympiad">Practice Olympiad</Link>
              </div>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>
        <div className="content">
          <Routes>
            <Route path="/" element={<Quiz />} />
            <Route path="/learn/basics" element={<LearnBasics />} />
            <Route path="/learn/compounds" element={<LearnCompounds />} />
            <Route path="/learn/periodic-table" element={<LearnPeriodicTable />} />
            <Route path="/learn/sig-figs" element={<SignificantFigures />} />
            <Route path="/quizzes" element={<Quizzes />} />
            <Route path="/quizzes/FiveQuestionQuiz" element={<FiveQuestionQuiz />} />
            <Route path="/quizzes/TenQuestionQuiz" element={<TenQuestionQuiz />} />
            <Route path="/quizzes/Olympiad" element={<Olympiad />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

