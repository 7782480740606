import React from "react";
import OlympiadPage from "./OlympiadPage";

function Olympiad() {

  return (
    <div>
      <OlympiadPage />
    </div>
  );
}

export default Olympiad;