import React, { useState, useEffect } from "react";
import axios from "axios";
import { Url } from './../global';
import Confetti from "react-confetti";

function QuizPage({ title, count, mode }) {
  const [userAnswers, setUserAnswers] = useState(Array(count).fill(""));
  const [score, setScore] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getQuestions = async() =>{
    const url = Url + "questions?count=" + count;
    const axiosOptions = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const response = await axios.get(url, axiosOptions);
    setQuestions(response.data);
  }

  const getOlympiad = async() =>{
      const url = Url + "dailyolympiad";
      const axiosOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      alert("reached here");
      const response = await axios.get(url, axiosOptions);
      setQuestionData(response.data);
      alert(response.data);

  }

  useEffect(() => {
    if( mode === "regular")
      getQuestions();
    else
      getOlympiad();
  }, []);

  const handleInputChange = (index, value) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
  };

  const handleSubmit = () => {
    let newScore = 0;
    questions.forEach((q, i) => {
      let answer = q.answer;
      if(q.answer != null) {
              answer = q.answer.replace(/\s*\(.*?\)\s*/g,'');
      }
      if (userAnswers[i] && answer.toLowerCase() === userAnswers[i].toLowerCase()) {
        newScore++;
      }
    });
    setScore(newScore);
  };

  const handleShare = async ( ) => {
    const shareText = `I scored ${score}/${questions.length} on the ${title}! Can you beat my score? Try it now at Compound Guesser!`;
    if (navigator.share) {
      try {
        await navigator.share({
              title: "Compound Guesser - Quiz Results",
              text: shareText,
              url: window.location.href,
            });
            alert("Thanks for sharing!");
        } catch (error) {
          if (error.name === "AbortError") {
              console.log("Share dialog closed by user.");
          } else {
              console.error("Error sharing content:", error);
              alert("An error occurred while trying to share.");
            }
        }
    } else {
      alert("Sharing not supported on this device. Copy this text to share:\n" + shareText + window.location.href);
    }
  };

  return (
    <div className="quiz-container">
      <h1>{title}</h1>
      <ol>
        {questions != null && questions.map((q, index) => (
          <li key={index}>
            <p>{q.question}</p>
            <input
              type="text"
              value={userAnswers[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              placeholder="Your answer"
            />
          </li>
        ))}
      </ol>
      <button onClick={handleSubmit}>Submit</button>
      {score !== null && (
        <div className="score-section">
          {score === questions.length && <Confetti />}
            <p className="score-text">
              {score === questions.length
                ? "🎉 Amazing! You got a Perfect Score! 🎉"
                : `You scored ${score}/${questions.length}. Keep going!`}
            </p>
            <button onClick={handleShare}>Share with Friends</button>
        </div>
      )}
    </div>
  );
}

export default QuizPage;
