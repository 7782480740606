import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Url } from './../global';

function OlympiadPage () {
  const [isVisible, setIsVisible] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [choices, setChoices] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [answer,setAnswer] = useState(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const answerVerify = () => {
      var answer = document.getElementById("answer").value;
      var actual = questionData.solution;

      if(answer != null && actual != null) {
        actual.trim();
        var answerString = "Correct Answer: " + answer.trim().toUpperCase() + ")";
        if(actual.startsWith(answerString)) {
          setAnswer(answer);
          setIsCorrect(true);
        }
        else {
          setAnswer(answer);
          setIsCorrect(false);
        }
      }
  };

  const getQuestions = async() =>{
    const url = Url + "dailyolympiad";
    const axiosOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
    }

    const response = await axios.get(url, axiosOptions);
    setQuestionData(response.data);
    if(questionData != null && questionData.choices != null) {
      let x = questionData.choices.replace("B)","<br/>B)");
      let y = x.replace("C)","&nbsp;C)");
      let z = y.replace("D)","<br/>D)");
      setChoices(z);
    }
  }

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className="quiz-container">
    { questionData != null && <div className="question">
      <h1 className="title">Chemistry Olympiad Practice</h1>
      <p>{questionData.content}</p>
      <div className="choices">
        {questionData.choices.split(/(?=\b[A-D]\) )/).map((choice, index) => (
          <div key={index} className="choice">
            {choice.trim()}
          </div>
        ))}
      </div>
      <p style={{ marginTop: "10px" }}>Answer: <input type="text" id="answer" size="1" placeHolder="Enter A,B,C or D"/></p>
      <button className="submit" onClick={answerVerify} onChange={answerVerify}> Go </button>
      {isCorrect && answer != null && <p style={{ marginTop: "10px",color:"#2F2" }}> Correct !! </p>}
      {!isCorrect && answer != null && <p style={{ marginTop: "10px",color:"#A22" }}> Take another guess </p>}
      <p style={{ marginTop: "10px",color:"#A22" }}> ---------------------------- </p>
      <button onClick={toggleVisibility}>
        {isVisible ? 'Hide Answer & Explanation' : 'Show Answer & Explanation'}
      </button>
      {isVisible && (
        <>
          <span className="solution">
            <strong>{questionData.solution}</strong>
          </span>
          <div className="explanation">
            <p>{questionData.explanation}</p>
          </div>
        </>
      )}
    </div> } </div>
  );
};

export default OlympiadPage;